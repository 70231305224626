import Layout from '@/layout'

const villageRouter = {
  path: '/register',
  component: Layout,
  children: [{
    path: '/',
    name: 'register',
    meta: {
      title: "疫情防控"
    },
    component: () =>
      import('@/views/fangyi/register.vue')
  }, {
    path: '/register/luo',
    name: 'luo',
    meta: {
      title: "返洛人员报备"
    },
    component: () =>
      import('@/views/fangyi/luo.vue')
  }, {
    path: '/register/chuqi',
    name: 'chuqi',
    meta: {
      title: "出洛人员报备"
    },
    component: () =>
      import('@/views/fangyi/chuqi.vue')
  }, {
    path: '/register/geli',
    name: 'geli',
    meta: {
      title: "管控人员报备"
    },
    component: () =>
      import('@/views/fangyi/geli.vue')
  }, {
    path: '/register/fangyiType',
    name: 'fangyiType',
    meta: {
      title: "防疫措施"
    },
    component: () =>
      import('@/views/fangyi/fangyiType.vue')
  }, {
    path: '/register/yimiao',
    name: 'yimiao',
    meta: {
      title: "疫苗接种排查"
    },
    component: () =>
      import('@/views/fangyi/yimiao.vue')
  }, {
    path: '/register/newAdd',
    name: 'newAdd',
    meta: {
      title: "居民信息登记"
    },
    component: () =>
      import('@/views/fangyi/newAdd.vue')
  }, {
    path: '/shop/myShop',
    name: 'myShop',
    meta: {
      title: "我的商铺"
    },
    component: () =>
      import('@/views/shop/myShop.vue')
  }, {
    path: '/shop/myShop/addShop/:shopId/:add/:shopCate',
    name: 'addShop',
    meta: {
      title: "商铺信息"
    },
    component: () =>
      import('@/views/shop/addShop.vue')
  }, {
    path: '/shop/myShop/myShopOrder/:shopId',
    name: 'myShop',
    meta: {
      title: "我的订单"
    },
    component: () =>
      import('@/views/shop/myShopOrder/index.vue')
  }, {
    path: '/shop/myShop/myShopOrder/orderXq/:OId/:shopId',
    name: 'orderXq',
    meta: {
      title: "详情"
    },
    component: () =>
      import('@/views/shop/myShopOrder/orderXq.vue')
  }, {
    path: '/register/actReport',
    name: 'actReport',
    meta: {
      title: "聚集性活动报备"
    },
    component: () =>
      import('@/views/fangyi/actReport.vue')
  }, {
    path: '/shop/myEnt',
    name: 'myEnt',
    meta: {
      title: "我的企业"
    },
    component: () =>
      import('@/views/shop/myEnt.vue')
  }, {
    path: '/shop/myShop/addEnt/:shopId/:add',
    name: 'addEnt',
    meta: {
      title: "企业信息"
    },
    component: () =>
      import('@/views/shop/addEnt.vue')
  }, {
    path: '/shop/myShop/ygList/:seId/:ekind',
    name: 'ygList',
    meta: {
      title: "员工信息"
    },
    component: () =>
      import('@/views/shop/yuangong/ygList.vue')
  }, {
    path: '/shop/myShop/ygList/addYg/:seId/:ekind/:add',
    name: 'addYg',
    meta: {
      title: "员工信息"
    },
    component: () =>
      import('@/views/shop/yuangong/addYg.vue')
  }, {
    path: '/shop/myShop/goodsList/:id/:shopCate',
    name: 'goodsList',
    meta: {
      title: "商品列表"
    },
    component: () =>
      import('@/views/shop/goodsList.vue')
  }, {
    path: '/shop/myShop/Promotion/:id',
    name: 'Promotion',
    meta: {
      title: "促销活动"
    },
    component: () =>
      import('@/views/shop/Promotion.vue')
  }, {
    path: '/shop/myShop/goodsList/goodsAdd/:id/:shopId/:goodsId',
    name: 'goodsAdd',
    meta: {
      title: "商品信息"
    },
    component: () =>
      import('@/views/shop/goodsAdd.vue')
  }, {
    path: '/shop/myShop/Promotion/:id',
    name: 'Promotion',
    meta: {
      title: "促销活动"
    },
    component: () =>
      import('@/views/shop/Promotion.vue')
  }, {
    path: '/shop/myShop/Promotion/promotionAdd/:id/:PromotionId/:shopId',
    name: 'promotionAdd',
    meta: {
      title: "促销活动"
    },
    component: () =>
      import('@/views/shop/promotionAdd.vue')
  }, {
    path: '/shop/myShop/goodsTypeList/:shopId',
    name: 'goodsAdd',
    meta: {
      title: "商品分类"
    },
    component: () =>
      import('@/views/shop/goodsTypeList.vue')
  }, {
    path: '/shop/myShop/goodsTypeList/goodsTypeListAdd/:id/:SortId/:shopId',
    name: 'goodsTypeListAdd',
    meta: {
      title: "商品分类"
    },
    component: () =>
      import('@/views/shop/goodsTypeListAdd.vue')
  }, {
    path: '/jifenShop/personal',
    meta: {
      title: "我的订单"
    },
    component: () =>
      import('@/views/fivestar/jifenShop/personal.vue')
  }, {
    path: '/shop/myShop/Promotion/PromotionPenple/:id',
    name: 'PromotionPenple',
    meta: {
      title: "报名信息"
    },
    component: () =>
      import('@/views/shop/PromotionPenple.vue')
  }, {
    path: '/register/changsuoAdd/:seId/:ekind/:title',
    name: 'changsuoAdd',
    meta: {
      title: "出入场所登记"
    },
    component: () =>
      import('@/views/fangyi/changsuoAdd.vue')
  },
  {
    path: '/fangyi/myGeli/mygeli',
    name: 'mygeli',
    meta: {
      title: "已上报隔离信息"
    },
    component: () =>
      import('@/views/fangyi/myGeli/mygeli.vue')
  },
  {
    path: '/register/fangyi/fanqiNew/fqShouye',
    name: 'fqShouye',
    meta: {
      title: "返(来)洛人员报备"
    },
    component: () =>
      import('@/views/fangyi/fanqiNew/fqShouye.vue')
  },
  {
    path: '/register/fangyi/fanqiNew/fqShouye/fqYou/:hid',
    name: 'fqYou',
    meta: {
      title: "返(来)洛人员报备"
    },
    component: () =>
      import('@/views/fangyi/fanqiNew/fqYou.vue')
  },
  {
    path: '/register/fangyi/fanqiNew/fqShouye/fqWu/:name/:idcard',
    name: 'fqWu',
    meta: {
      title: "返(来)洛人员报备"
    },
    component: () =>
      import('@/views/fangyi/fanqiNew/fqWu.vue')
  },
  {
    path: '/register/fangyi/chuqiNew/cqShouye',
    name: 'cqShouye',
    meta: {
      title: "在外人员登记"
    },
    component: () =>
      import('@/views/fangyi/chuqiNew/cqShouye.vue')
  },
  {
    path: '/register/fangyi/chuqiNew/cqShouye/cqYou/:hid',
    name: 'cqYou',
    meta: {
      title: "在外人员登记"
    },
    component: () =>
      import('@/views/fangyi/chuqiNew/cqYou.vue')
  },
  {
    path: '/register/fangyi/chuqiNew/cqShouye/cqWu/:name/:idcard',
    name: 'cqWu',
    meta: {
      title: "在外人员登记"
    },
    component: () =>
      import('@/views/fangyi/chuqiNew/cqWu.vue')
  },
  {
    path: '/register/fangyi/yimiaoNew/ymShouye',
    name: 'ymShouye',
    meta: {
      title: "疫苗接种登记"
    },
    component: () =>
      import('@/views/fangyi/yimiaoNew/ymShouye.vue')
  },
  {
    path: '/register/fangyi/yimiaoNew/ymShouye/ymYou/:hid',
    name: 'ymYou',
    meta: {
      title: "疫苗接种登记"
    },
    component: () =>
      import('@/views/fangyi/yimiaoNew/ymYou.vue')
  },
  {
    path: '/register/fangyi/yimiaoNew/ymShouye/ymWu/:name/:idcard',
    name: 'ymWu',
    meta: {
      title: "疫苗接种登记"
    },
    component: () =>
      import('@/views/fangyi/yimiaoNew/ymWu.vue')
  },
  {
    path: '/register/fangyi/myFangKong/fkFanQi',
    name: 'fkFanQi',
    meta: {
      title: "我的返(来)洛报备信息"
    },
    component: () =>
      import('@/views/fangyi/myFangKong/fkFanQi.vue')
  },
  {
    path: '/register/fangyi/myFangKong/fkFanQi/fanQiHs/:id',
    name: 'fanQiHs',
    meta: {
      title: "核酸检测结果报备"
    },
    component: () =>
      import('@/views/fangyi/myFangKong/fanQiHs.vue')
  },
  {
    path: '/register/fangyi/myFangKong/fkFanQi/fanQiHs/fqHeSuanAdd/:id/:add',
    name: 'fqHeSuanAdd',
    meta: {
      title: "核酸检测结果"
    },
    component: () =>
      import('@/views/fangyi/myFangKong/fqHeSuanAdd.vue')
  },
  {
    path: '/register/fangyi/myFangKong/fkFanQi/mkfqXiangQing/:id',
    name: 'mkfqXiangQing',
    meta: {
      title: "我的返(来)洛报备详情"
    },
    component: () =>
      import('@/views/fangyi/myFangKong/mkfqXiangQing.vue')
  },
  {
    path: '/register/fangyi/myYiMiao/yimiaoList',
    name: 'yimiaoList',
    meta: {
      title: "我的疫苗接种信息"
    },
    component: () =>
      import('@/views/fangyi/myYiMiao/yimiaoList.vue')
  },
  {
    path: '/register/fangyi/myYiMiao/yimiaoList/yiMiaoUpdata/:id',
    name: 'yiMiaoUpdata',
    meta: {
      title: "疫苗接种信息"
    },
    component: () =>
      import('@/views/fangyi/myYiMiao/yiMiaoUpdata.vue')
  },
  {
    path: '/fangyi/fangkong/fkShouYe',
    name: 'fkShouYe',
    meta: {
      title: "我的报备信息"
    },
    component: () =>
      import('@/views/fangyi/fangkong/fkShouYe.vue')
  },
  {
    path: '/fangyi/fangkong/fkShouYe/zaiweixiangqing/:id',
    name: 'zaiweixiangqing',
    meta: {
      title: "在外人员详情"
    },
    component: () =>
      import('@/views/fangyi/fangkong/zaiweixiangqing.vue')
  },
  {
    path: '/fangyi/fangkong/fkShouYe/hesuanXq/:id',
    name: 'hesuanXq',
    meta: {
      title: "核酸检测详情"
    },
    component: () =>
      import('@/views/fangyi/fangkong/hesuanXq.vue')
  },
  {
    path: '/wuye/wuyeList',
    name: 'wuyeList',
    meta: {
      title: "红色物业"
    },
    component: () =>
      import('@/views/wuye/wuyeList.vue')
  },
  {
    path: '/wuye/wuyeList/wuyeXiangQing/:id',
    name: 'wuyeXiangQing',
    meta: {
      title: "物业公司详情"
    },
    component: () =>
      import('@/views/wuye/wuyeXiangQing.vue')
  },
  {
    path: '/wuye/wuyeList/wuyeXiangQing/fuwuXiangQing/:id',
    name: 'fuwuXiangQing',
    meta: {
      title: "服务详情"
    },
    component: () =>
      import('@/views/wuye/fuwuXiangQing.vue')
  },
  {
    path: '/wuye/wuyeList/wuyeXiangQing/fuwuXiangQing/proServiceAdd/:ProSerId/:ProId',
    name: 'proServiceAdd',
    meta: {
      title: "物业服务调查"
    },
    component: () =>
      import('@/views/wuye/proServiceAdd.vue')
  },
  {
    path: '/register/fangyi/hesuan/hesuanAdd',
    name: 'hesuanAdd',
    meta: {
      title: "核酸检测登记"
    },
    component: () =>
      import('@/views/fangyi/hesuan/hesuanAdd.vue')
  },
  {
    path: '/dayao/dayaoShouYe',
    name: 'dayaoShouYe',
    meta: {
      title: "核酸检测结果查询"
    },
    component: () =>
      import('@/views/dayao/dayaoShouYe.vue')
  },
  {
    path: '/dayao/login',
    name: 'login',
    meta: {
      title: "登录"
    },
    component: () =>
      import('@/views/dayao/login.vue')
  },
  {
    path: '/dayao/info',
    name: 'info',
    meta: {
      title: "大尧检验"
    },
    component: () =>
      import('@/views/dayao/info.vue')
  },
  {
    path: '/dayao/dayaoShouYe/dayaoPdf/:id',
    name: 'dayaoPdf',
    meta: {
      title: "核酸检测报告"
    },
    component: () =>
      import('@/views/dayao/dayaoPdf.vue')
  },
  {
    path: '/dayao/tiaoxingma',
    name: 'tiaoxingma',
    meta: {
      title: "条形码"
    },
    component: () =>
      import('@/views/dayao/tiaoxingma.vue')
  },
  ]
}

export default villageRouter